<template>
  <a-timeline class="see" v-if="listData.length > 0">
    <a-timeline>
      <a-timeline-item class="tool_w_30" v-for="item in listData" :key="item.name">
        <template #dot>
          <p class="see-drawer-dot-p">{{ item.S }}</p>
          <p class="see-drawer-dot-p1">{{ item.M }}</p>
        </template>
        <div class="see-drawer-content">
          <p>
            <!-- <a-tag v-show='item.followType != null'>{{ item.followType.label }}</a-tag> -->
            <a-tag v-show="item.followLabelName">{{ item.followLabelName }}</a-tag>
            <a-tag v-show="item.intentionTypeName">{{ item.intentionTypeName }}</a-tag>
          </p>
          <p v-show="item.followType">跟进方式：{{ item.followType?.label }} {{ item.followTelType?.label }}</p>
          <p v-show="item.isWechat">加微信：{{ item.isWechat?.label }}</p>
          <p v-show="item.consignmentType">托运时间：{{ item.consignmentType?.label }}</p>
          <p v-show="item.consignmentPurpose">托运用途：{{ item.consignmentPurpose?.label }}</p>
          <p v-show="item.receiver">对接人：{{ item.receiver }}</p>
          <p v-show="item.intentionLevel">意向等级：{{ item.intentionLevel?.label }}</p>
          <p>跟进内容：{{ item.content }}</p>
          <div class="see-drawer-content-imgArry">
            <a-image-preview-group>
              <div class="see-drawer-content-imgArry-for" v-for="itemImg in item.fileUrls" :key="itemImg.id">
                <a-image :src="itemImg.url" style="width:40px;height:40px"/>
              </div>
            </a-image-preview-group>
          </div>
          <p class="see-drawer-content-time" style="color:#0066CC" v-if="item.followNextTime">
            <icon-font type="hy-shijian" class="tool-icon" /> 下次联系时间：{{ item.followNextTime }} {{ item.followNextContent}}
          </p>
          <p class="see-drawer-content-time" style="color: gray">{{ item.followName }} {{ item.followTime }}</p>
          <!-- <p class="see-drawer-content-time">{{ item.intentionTypeName }} </p>
          <p>{{ item.content }}</p> -->

        </div>
      </a-timeline-item>
    </a-timeline>
    <a-pagination show-quick-jumper v-model:current="pagination.current" :pageSize="pagination.pageSize" :show-total="pagination.showTotal"
      :page-size-options="pagination.pageSizeOptions" :total="pagination.total" @change="pagination.onChange" />

  </a-timeline>
  <a-empty v-else description="暂无跟进记录" />
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { contactCustomerIdPage } from '@/api/crmManagement/follow'
import { createFromIconfontCN } from '@ant-design/icons-vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default {
  props: {
    customerId: String
  },
  setup (props, context) {
    const state = reactive({
      cid: '',
      loading: false,
      listData: [],
      pagination: {
        pageSize: 4,
        pageSizeOptions: ['4', '10', '20', '40', '50'],
        current: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      }
    })
    const loadData = () => {
      state.loading = true
      contactCustomerIdPage(state.cid, {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          context.emit('data', res.data.records)
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
          state.listData.forEach(e => {
            if (e.followTime != null) {
              const f = e.followTime.split(' ')[0]
              const arry = f.split('-')
              e.M = arry[0] + '-' + arry[1]
              e.S = arry[2]
            }
          })
        }
      }).catch(err => {
        console.log('分页错误', err)
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(() => {
      if (props.customerId) {
        state.cid = props.customerId
      }
      loadData()
    })
    return {
      ...toRefs(state),
      loadData
    }
  },
  components: {
    IconFont
  }
}
</script>
<style lang="less" scoped>
@import '../../tool/index.less';
@import './index.less';

:deep(.ant-timeline-item-head-custom) {
  left: 35px !important;
}
</style>
